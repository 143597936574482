@mixin breakpoint($point) {
    @if $point==desktop {
        @media (max-width: 1200px) {
            @content;
        }
    }

    @else if $point==small-desktop {
        @media (max-width: 992px) {
            @content;
        }
    }

    @else if $point==tablet {
        @media (max-width: 768px) {
            @content;
        }
    }

    @else if $point==mobile {
        @media (max-width: 480px) {
            @content;
        }
    }

    @else if $point==smaller-phone {
        @media (max-width: 320px) {
            @content;
        }
    }
}