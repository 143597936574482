// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
// Variables
@import 'variables';

// Mixin
@import 'mixin';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


html,
body {
    width: 100% !important;
    height: 100% !important;
}

body {
    font-family: $font-family-sans-serif !important;
    font-weight: 300 !important;
    overflow-x: hidden;
}

strong {
    font-weight: 400 !important;
}

ul {
    list-style: none;
    padding: 0;
    margin: unset;
}

main {
    overflow: hidden;

}

p {
    margin: unset;
}

a:hover {
    color: #0071dc;
    text-decoration: none !important;
}

em {
    font-style: normal;
}

*:focus {
    outline: 0 !important;
}

.container-sm {
    max-width: 700px;
    width: auto;
}

.col-mw-24 {
    flex: 0 0 24%;
    max-width: 24%;
}

.col-mw-33 {
    flex: 0 0 33%;
    max-width: 33%;
}

.dark-theme {
    background-color: #000;
    color: white;
}

.blue-theme {
    background-color: rgb(3, 32, 51);
    ;
    color: white;
}

.text-shadow {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7), 0 0 5px rgba(0, 0, 0, 0.8);
}

.spacer {
    height: 180px;
}

.font-300 {
    font-weight: 300;
}

.lh-lg {
    line-height: 2.5;
}

.m_content-title-last {
    padding: 180px 0 0;
}

.bg-grey {
    background-color: #f4f4f4;
}


.icon-more {
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
}

.more:hover>.icon-more,
.no-link-effect:hover .icon-more {
    transform: translate(10px, 0);
    -webkit-transform: translate(10px, 0);
    -o-transform: translate(10px, 0);
    -moz-transform: translate(10px, 0);
}

.no-link-effect {
    text-decoration: none;
    color: unset;

    &:hover {
        color: unset !important;
    }
}

header {
    a {
        color: $gray;
    }

    #tout-deb-menu {
        overflow: hidden;
        max-height: 0;
        -webkit-transition: max-height .600s ease;
        -moz-transition: max-height .600s ease;
        -o-transition: max-height .600s ease;
        transition: max-height .600s ease;
        position: absolute;
        width: 100%;
        height: auto;
        background: white;
        margin-top: 57px;
        top: 0;
        left: 0;
        z-index: 99999;
    }

    .tout-deb-menu-active {
        max-height: 500px !important;
    }
}

footer {
    a {
        color: $gray;
    }

    #footer-top {
        min-height: 3vw;

        #rs {
            li {
                padding-right: 10px;
            }
        }

        button {
            padding: unset;
        }

        @include breakpoint(small-desktop) {
            text-align: center;

            #backtotop {
                opacity: 0;
            }
        }
    }

    #footer-middle {
        border-top: 1px solid #ddd;

        .footer-sitemap {
            border-left: 1px solid #ddd;
            padding: 16px 24px 50px;

            @include breakpoint(small-desktop) {
                border-left: 0;
                padding: 10px 24px 0px;
            }

            .item-list {
                @include breakpoint(small-desktop) {
                    display: none;
                    overflow: hidden;
                    -webkit-transition-property: height;
                    transition-property: height;
                    display: none;
                    height: 0;
                }
            }

            .item-list--toggle {
                @include breakpoint(small-desktop) {
                    display: block;
                    height: auto;
                }
            }

            .item-list-title {
                display: block;
                min-height: 2.8vw;

                @include breakpoint(small-desktop) {
                    height: 5.55555556vw;
                    font-size: 1.1rem;
                }

                @include breakpoint(tablet) {
                    height: 8.55555556vw;
                    font-size: 1.1rem;
                }

                @include breakpoint(mobile) {
                    height: 11.55555556vw;
                    font-size: 1.1rem;
                }

            }

            .item-link {
                padding-bottom: 5px;

                @include breakpoint(small-desktop) {
                    padding-bottom: 15px;
                }
            }

            .footer-category__anchor {
                display: none;

                @include breakpoint(small-desktop) {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 5.55555556vw;

                    &::after {
                        content: '';
                        display: block;
                        width: auto;
                        height: 1px;
                        background-color: #e7e7e7;
                        margin: 0 23px;
                        margin-top: 5.55555556vw;
                    }

                    .icon {
                        position: absolute;
                        top: 55%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        right: 5.38888889vw;
                        z-index: -1;
                    }
                }

                @include breakpoint(tablet) {
                    height: 8.55555556vw;

                    &::after {
                        margin-top: 8.55555556vw;
                    }
                }

                @include breakpoint(mobile) {
                    height: 12.55555556vw;

                    &::after {
                        margin-top: 12.55555556vw;
                    }
                }
            }

            .footer-category__anchor--active {
                @include breakpoint(small-desktop) {
                    &::after {
                        display: none;
                    }

                    .icon {
                        -webkit-transform: translateY(-50%) rotate(180deg);
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }
        }

        .footer-sitemap:first-child {
            border-left: 0;
        }
    }

    #footer-bottom {
        border-top: 1px solid #ddd;
        min-height: 3vw;
        font-size: 13px;

        @include breakpoint(small-desktop) {
            border-top: 0;
            margin-top: 1.5rem;
            font-size: 13px;
        }

        #links {
            display: flex;
            flex-direction: row;

            @include breakpoint(small-desktop) {
                padding: 15px 0;
                text-align: center;
                display: block;
            }

            .links-item {
                margin-left: 20px;

                @include breakpoint(small-desktop) {
                    margin-left: 0px;
                    display: inline-block;

                    a {
                        padding: 2.22222222vw 3.33333333vw;
                    }
                }
            }
        }

        .rightReserved {
            @include breakpoint(small-desktop) {
                text-align: center;
            }
        }
    }

    .row {
        @include breakpoint(small-desktop) {
            flex-direction: column;
            -ms-flex-direction: column;
        }
    }
}

#btn_helpfulmenu_mail {
    bottom: 78px;
}

.container {
    max-width: $CONTAINER_WIDTH_DESKTOP;
    margin: 0 auto;

    @include breakpoint(small-desktop) {
        max-width: $CONTAINER_WIDTH_SMALL_DESKTOP;
    }

    @include breakpoint(tablet) {
        max-width: $CONTAINER_WIDTH_TABLET;
    }

    @include breakpoint(mobile) {
        max-width: $CONTAINER_WIDTH_MOBILE;
    }


}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none !important;
    padding: 0 !important;
}

// .fab.show,
// .fab-left.show {
//     opacity: 1;
//     visibility: visible;
//     -webkit-transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
//     transition: opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
// }

.fab {
    position: fixed;
    direction: ltr;
    right: 32px;
    bottom: 24px;
    z-index: 2500;
    width: 48px;
    height: 48px;
    font-size: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility .1s .2s, opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: visibility .1s .2s, opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
}

.fab-left {
    position: fixed;
    direction: ltr;
    left: 32px;
    bottom: 24px;
    z-index: 2500;
    width: 48px;
    height: 48px;
    font-size: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: visibility .1s .2s, opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: visibility .1s .2s, opacity .2s cubic-bezier(0.4, 0, 0.2, 1);
}

button {
    border: 0;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    border-radius: 0;
    cursor: pointer;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

.fab__icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 24px;
}

.active {
    font-weight: 500 !important;
}

.floating-navigation {
    overflow: visible;

    &::after {
        content: '';
        display: block;
        height: 86px;
    }
}

.floating-navigation__wrap {
    background: rgba(0, 0, 0, 0.55) !important;
    color: white;
    position: absolute;
    max-width: $CONTAINER_WIDTH_DESKTOP;
    left: 0;
    right: 0;
    width: 100%;
    height: 86px;
    margin: 0 auto;
    z-index: 1999;

    &::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: transparent;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 36px;
        opacity: .3;
    }

    .floating-navigation__headline-text {
        display: inline-block;
        overflow: hidden;
        height: 50px;
        font-size: 18px;
        line-height: 50px;
        color: #fff;
    }

    .floating-navigation__inner {
        max-width: $CONTAINER_WIDTH_DESKTOP;
        position: relative;
        margin: 0 auto;
        height: 100%;
    }

    .floating-navigation__utility {
        position: absolute;
        top: 0;
        right: 0;
        white-space: nowrap;
        z-index: 3;
    }

    .floating-navigation__utility-list {
        display: inline-block;
        margin-right: 20px;
        vertical-align: top;

        &::after {
            content: '';
            display: inline-block;
            height: 50px;
            width: 0;
            font-size: 0;
            vertical-align: middle;
        }

        .floating-navigation__utility-item {
            display: inline-block;
            vertical-align: middle;

            a {
                display: block;
                overflow: hidden;
                padding: 0 15px;
                height: 50px;
                line-height: 52px;
                font-size: 11px;
                font-weight: 400;
                color: #fff;
                text-decoration: none;
                text-transform: uppercase;
            }
        }
    }

    .floating-navigation__menu {
        position: absolute;
        left: auto;
        right: auto;
        bottom: 0;
        width: 100%;
        height: 35px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        z-index: 2;

        .floating-navigation__menu-list {
            position: absolute;
            left: 0;
            display: table;

            .floating-navigation__menu-item {
                display: table-cell;
                vertical-align: middle;
                padding: 2px 0;

                a {
                    position: relative;
                    display: block;
                    padding: 0 20px;
                    height: 31px;
                    line-height: 31px;
                    font-size: 11px;
                    font-weight: 400;
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;

                }
            }
        }

        .floating-navigation__menu-bar {
            position: absolute;
            left: 20px;
            right: auto;
            top: 32px;
            height: 3px;
            background: #fff;
            z-index: 1;
            -webkit-transition: width 1s, left 1s, -webkit-transform 1s;
            transition: width 1s, left 1s, -webkit-transform 1s;
            transition: width 1s, left 1s, transform 1s;
            transition: width 1s, left 1s, transform 1s, -webkit-transform 1s;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
    }

    .floating-navigation__button-wrap {
        display: inline-block;
        vertical-align: top;
    }

    .floating-navigation__button {
        display: inline-block;
        width: 180px;
        height: 50px;
        line-height: 50px;
        background: #0074c2;
        border-radius: 0;
        font-size: 13px;
        font-weight: 400;
        padding: 0;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        overflow: hidden;
        text-decoration: none;
    }
}

.navbar {
    padding: 0.5rem 0rem;
}

.navbar-light .navbar-toggler {
    border: 0;
}

.sub-h2 {
    font-size: 1.8rem;
    font-weight: 400;

    @include breakpoint(small-desktop) {
        font-size: 0.9rem;
    }
}

.btn-slide {
    padding: 10px 25px;
}

.fixedTop {
    position: fixed !important;
    top: 0 !important;
}

.position-static {
    position: static;
}

.fixed-area {
    height: 120vh;
    position: relative;
}

.fixed-area-xxs {
    height: 30;
    position: relative;
}

.fixed-area-xs {
    height: 70vh;
    position: relative;
}

.fixed-area-sm {
    height: 100vh;
    position: relative;
}

.fixed-area-lg {
    height: 150vh;
    position: relative;
}

.fixed-area-xl {
    height: 180vh;
    position: relative;
}

.fixed-area-xxl {
    height: 220vh;
    position: relative;
}

.m_content-title {
    padding: 180px 0;
}

.little-title {
    font-size: 1.1rem;
    font-weight: 300;

    @include breakpoint(small-desktop) {
        font-size: 0.9rem;
    }
}

.slide {
    height: 440px;
}

.no-padding {
    padding: 0 !important
}

.card {
    border-radius: 10px;
}

.rotate-transition {
    transition-duration: 0.3s;
    transition-property: transform;
}

.rotate180 {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.page-description {
    font-size: 1rem;
}

.btn-text {
    &:hover {
        text-decoration: underline;
        transition-property: transform;
        transform: scale(1.1);
    }
}

@media (max-width: $lg) {
    #tout-deb {
        display: none;
    }

    #tout-deb-menu {
        display: none;
    }

    .floating-navigation__utility-list {
        display: none !important;
    }

    .floating-navigation__menu-list {
        right: auto;
        left: 0px;
        margin-left: 0px;
    }

}

.mobile__text-center {
    @include breakpoint(small-desktop) {
        text-align: center;
    }
}

.h-120vh {
    @include breakpoint(small-desktop) {
        height: 120vh !important;
    }
}

.h-150vh {
    @include breakpoint(small-desktop) {
        height: 150vh !important;
    }
}

.h-180vh {
    @include breakpoint(small-desktop) {
        height: 180vh !important;
    }
}